import React from "react";
import { useState, useContext } from "react";
import { firebaseStorage } from "../Utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import FunctionContext from "../Utils/FunctionContext";
import UserContext from "../Utils/UserContext";
import "../../Styles/UploadScreen.css";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";

export default function UploadPanel() {
    const [progressPercent, setProgressPercent] = useState(0);
    const [uploadStarted, setUploadStatus] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);

    const { nextPage, previousPage } = useContext(FunctionContext);
    const { userVideoUrl, setUserVideoUrl } = useContext(UserContext);

    function OnVideoSubmit()
    {
        logEvent(firebaseAnalytics, 'Submitted Video')
        nextPage();
    }

    function selectFile() {
        document.getElementById("fileInput").click();
    }

    function isCorrectExtension(filename) {
        var allowedExtensions = ["mov", "mp4", "avi"];
        // Get the file extension
        var extension = filename.split(".").pop().toLowerCase();

        // Check if the file extension is in the list of allowed extensions
        return allowedExtensions.includes(extension);
    }

    var inputs = document.querySelectorAll("input");

    // Loop through all input elements and assign the onfocus event
    inputs.forEach(function (input) {
        input.addEventListener("blur", function () {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
    });

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: "Tooyumm x Bigboss",
                    text: "Grab a chance to be the biggest big boss fan. Click on the link to participate.",
                    url: document.location.href,
                });
                console.log("Content shared successfully");
            } catch (error) {
                console.error("Error sharing content:", error);
            }
        } else {
            console.log("Web Share API is not supported in this browser.");
        }
    };

    function onFileSelected(e) {
        console.log("File changed");
        const uploadText = document.getElementById("UploadText");
        var file = e.target.files[0];
        const selectedFileText = document.getElementById("selectedFileText");
        setFileSelected(true);
        if (!file) {
            selectedFileText.style.display = "none"
            uploadText.innerHTML = "Tap here to upload"
            setFileSelected(false);
            selectedFileText.innerHTML = "No file selected";
            return;
        }
        
        console.log("File selected " + file.name);
        if (!isCorrectExtension(file.name)) {
            selectedFileText.style.display = "none"
            uploadText.innerHTML = "Tap here to upload"
            alert("Please enter a valid file format");
            file = null;
            setFileSelected(false);
            return;
        }

        if (file.size > 200 * 1024 * 1024) {
            selectedFileText.style.display = "none"
            uploadText.innerHTML = "Tap here to upload"
            alert("File is too big!! Maximum file size if 200 MB.");
            file = null;
            selectedFileText.innerHTML = "No file selected";
            setFileSelected(false);
            return;
        }

        
        uploadText.innerHTML = "Tap here to Re-Upload"
        selectedFileText.style.display = "block"
        selectedFileText.innerHTML = "File selected";
        setFileSelected(true);
    }

    const handleFileSelect = () => {
        const fileInput = document.getElementById("fileInput");
        const uploadText = document.getElementById("UploadText");
        var file = fileInput?.files[0];

        if (!file) {
            return;
        }

        if (file.size > 200 * 1024 * 1024) {
            alert("File is too big!! Maximum file size if 200 MB.");
            file = null;
            return;
        }

        const storageRef = ref(
            firebaseStorage,
            `files/${Date.now() + "_" + file.name}`
        );
        document.getElementById("selectedFileText").style.display = "none";

        setFileSelected(true);
        const uploadTask = uploadBytesResumable(storageRef, file);        
        setUploadStatus(true);
        const tapToUploadContainer = document.getElementById(
            "tapToUploadContainer"
        );
        const uploadButton = document.getElementById("submitVideo");
        tapToUploadContainer.classList.add("hidden");
        uploadButton.classList.add("hidden");

        uploadTask.on(
            "state_changed",
            (snaphsot) => {
                const progress = Math.round(
                    (snaphsot.bytesTransferred / snaphsot.totalBytes) * 100
                );
                setProgressPercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("Video Uploaded");
                    console.log(downloadURL);
                    setUserVideoUrl(downloadURL);
                    document.getElementById("fileInput").value = '';
                    uploadText.innerHTML = "Tap here to upload"
                    document.getElementById("selectedFileText").style.display = "none";
                    setFileSelected(false);                    
                    setUploadStatus(false);
                    // nextPage();
                    OnVideoSubmit();
                    const tapToUploadContainer = document.getElementById(
                        "tapToUploadContainer"
                    );
                    const uploadButton = document.getElementById("submitVideo");
                    tapToUploadContainer.classList.remove("hidden");
                    uploadButton.classList.remove("hidden");
                });
            }
        );
    };

    return (
        <div className="panel">
            <div className="navBar">
                <div onClick={previousPage} className="backBtn"></div>
                <div onClick={handleShare} className="shareBtn"></div>
            </div>
            <input
                id="fileInput"
                style={{ display: "none" }}
                onChange={onFileSelected}
                type="file"
                accept=".mp4,.avi,.mov"
                multiple={false}
            />
            <div className="tooyumxbb"></div>

            <div className="uploadText"></div>
            <div
                id="tapToUploadContainer"
                onClick={selectFile}
                className="tapToUpload "
            >
                <p id="UploadText">Tap here to upload</p>
            </div>
            {fileSelected && (
                <button
                    className=""
                    id="submitVideo"
                    onClick={handleFileSelect}
                >
                    Submit Video
                </button>
            )}

            <div className="legal"></div>
            <p id="selectedFileText"></p>
            {uploadStarted && (
                <>
                    <div className="progressBarOuter">
                        <div className="progressBarOuterUpper"></div>
                        <div
                            className="progressBarInner"
                            style={{ width: `${progressPercent}%` }}
                        ></div>
                    </div>
                    <div className="progressBarText">
                        {" "}
                        <span className="largerText">
                            {progressPercent}%{" "}
                        </span>{" "}
                        Uploaded...
                    </div>
                </>
            )}            
        </div>
    );
}
