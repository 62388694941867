import React, { useContext } from "react";
import FunctionContext from "../Utils/FunctionContext";
import "../../Styles/splashScreen.css"
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";

function SplashScreen() {

    const {nextPage, previousPage} = useContext(FunctionContext);

    function callParticipate()
    {
        logEvent(firebaseAnalytics, 'Clicked on participate now')
        nextPage();
    }

    return (
        <>
            <div className="panel">
                <div className="tooyumxbbSplash"></div>                
                <div className="splashScreenText"></div>
                <button className="button" id="participateButton" onClick={callParticipate}>Participate Now</button>
                <div className="jioCinema"></div>
                <div className="legal"></div>
                
            </div>
        </>
    );
}

export default SplashScreen;
