import React, { useState, useContext } from "react";
import FunctionContext from "../Utils/FunctionContext";
import "../../Styles/ParticipateScreen.css";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";
import UserContext from "../Utils/UserContext";

import { firebaseStorage } from "../Utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function ParticipateScreen() {
    const [progressPercent, setProgressPercent] = useState(0);
    const [uploadStarted, setUploadStatus] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const { userVideoUrl, setUserVideoUrl } = useContext(UserContext);

    function OnVideoSubmit() {
        logEvent(firebaseAnalytics, "Submitted Video");
        nextPage();
    }

    function selectFile() {
        document.getElementById("fileInput").click();
    }

    function isCorrectExtension(filename) {
        var allowedExtensions = ["mov", "mp4", "avi"];
        // Get the file extension
        var extension = filename.split(".").pop().toLowerCase();

        // Check if the file extension is in the list of allowed extensions
        return allowedExtensions.includes(extension);
    }

    var inputs = document.querySelectorAll("input");

    // Loop through all input elements and assign the onfocus event
    inputs.forEach(function (input) {
        input.addEventListener("blur", function () {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
    });

    function onFileSelected(e) {        
        const uploadText = document.getElementById("UploadText");
        var file = e.target.files[0];
        const selectedFileText = document.getElementById("selectedFileText");
        setFileSelected(true);
        if (!file) {
            selectedFileText.style.display = "none";
            uploadText.innerHTML = "Tap here to upload";
            setFileSelected(false);
            selectedFileText.innerHTML = "No file selected";
            return;
        }
        
        if (!isCorrectExtension(file.name)) {
            selectedFileText.style.display = "none";
            uploadText.innerHTML = "Tap here to upload";
            alert("Please enter a valid file format");
            file = null;
            setFileSelected(false);
            return;
        }

        if (file.size > 200 * 1024 * 1024) {
            selectedFileText.style.display = "none";
            uploadText.innerHTML = "Tap here to upload";
            alert("File is too big!! Maximum file size if 200 MB.");
            file = null;
            selectedFileText.innerHTML = "No file selected";
            setFileSelected(false);
            return;
        }

        uploadText.innerHTML = "Tap here to Re-Upload";
        selectedFileText.style.display = "block";
        selectedFileText.innerHTML = "File selected";
        setFileSelected(true);
    }

    const handleFileSelect = () => {
        const fileInput = document.getElementById("fileInput");
        const uploadText = document.getElementById("UploadText");
        var file = fileInput?.files[0];

        if (!file) {
            logEvent(firebaseAnalytics, "Tried to submit without video selected")
            alert("Please select a video to proceed");
            return;
        }

        if (file.size > 200 * 1024 * 1024) {
            logEvent(firebaseAnalytics, "File size too big selected");
            alert("File is too big!! Maximum file size if 200 MB.");
            file = null;
            return;
        }

        const storageRef = ref(
            firebaseStorage,
            `files/${Date.now() + "_" + file.name}`
        );
        document.getElementById("selectedFileText").style.display = "none";

        setFileSelected(true);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setUploadStatus(true);
        const tapToUploadContainer = document.getElementById(
            "tapToUploadContainer"
        );
        const uploadButton = document.getElementById("submitVideo");
        tapToUploadContainer.classList.add("hidden");
        uploadButton.classList.add("hidden");

        uploadTask.on(
            "state_changed",
            (snaphsot) => {
                const progress = Math.round(
                    (snaphsot.bytesTransferred / snaphsot.totalBytes) * 100
                );
                setProgressPercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {                                        
                    setUserVideoUrl(downloadURL);
                    document.getElementById("fileInput").value = "";
                    uploadText.innerHTML = "Tap here to upload";
                    document.getElementById("selectedFileText").style.display =
                        "none";
                    setFileSelected(false);
                    setUploadStatus(false);
                    // nextPage();
                    OnVideoSubmit();
                    const tapToUploadContainer = document.getElementById(
                        "tapToUploadContainer"
                    );
                    const uploadButton = document.getElementById("submitVideo");
                    tapToUploadContainer.classList.remove("hidden");
                    uploadButton.classList.remove("hidden");
                });
            }
        );
    };

    const { nextPage, previousPage } = useContext(FunctionContext);

    return (
        <>
            <div className="panel">
  
                <div className="tooyumxbbHome"></div>
                <div className="blankSpace"></div>
                <div className="splashScreenText"></div>
                <div className="blankSpace"></div>
                <div className="steps"></div>
                <div className="blankSpace"></div>
                <div className="blankSpace"></div>
                <div className="panel">
                    <input
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={onFileSelected}
                        type="file"
                        accept=".mp4,.avi,.mov"
                        multiple={false}
                    />

                    <div className="uploadText"></div>
                    <div
                        id="tapToUploadContainer"
                        onClick={selectFile}
                        className="tapToUpload "
                    >
                        <p id="UploadText">Tap here to upload</p>
                    </div>
                    
                    {/* fileSelected */}
                    {true && (
                        <button
                            className=""
                            id="submitVideo"
                            onClick={handleFileSelect}
                        >
                            Submit Video
                        </button>
                    )}

                    <div className="blankSpace"></div>

                    
                    <p id="selectedFileText"></p>
                    {/* uploadStarted */}
                    {uploadStarted && (
                        <>
                            <div className="progressBarOuter">
                                <div className="progressBarOuterUpper"></div>
                                <div
                                    className="progressBarInner"
                                    style={{ width: `${progressPercent}%` }}
                                ></div>
                            </div>
                            <div className="progressBarText">
                                {" "}
                                <span className="largerText">
                                    {progressPercent}%{" "}
                                </span>{" "}
                                Uploaded...
                            </div>
                        </>
                    )}
                  <div className="jioCinemaHome"></div>
                  <div className="legal"></div>
                </div>
            </div>
        </>
    );
}

export default ParticipateScreen;
