import React, { useContext } from "react";
import FunctionContext from "../Utils/FunctionContext";
import "../../Styles/DataUploadScreen.css";
import UserContext from "../Utils/UserContext";
import { firebase } from "../Utils/firebase";
import { getDatabase, ref, set } from "firebase/database";
import "firebase/database";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";

const database = getDatabase(firebase);

const handleShare = async () => {
    if (navigator.share) {
        try {
            await navigator.share({
                title: "Tooyumm x Bigboss",
                text: "Grab a chance to be the biggest big boss fan. Click on the link to participate.",
                url: document.location.href,
            });
            console.log("Content shared successfully");
        } catch (error) {
            console.error("Error sharing content:", error);
        }
    } else {
        console.log("Web Share API is not supported in this browser.");
    }
};

function DataUploadScreen() {
    const { nextPage, previousPage } = useContext(FunctionContext);
    const { userVideoUrl, setUserVideoUrl } = useContext(UserContext);
    var numm;


    const nameInput = document.getElementById("nameInput");
    const contactInput = document.getElementById("numberInput");
    const emailInput = document.getElementById("emailInput");

    // unnAuthUsers
    const writeUserData = (userID, videoURL) => {        
        set(ref(database, "unnAuthUsers/" + userID), {
            videoURL: videoURL,
            username: nameInput.value,
            userPhone: contactInput.value,
            userEmail: emailInput.value,
        }).then(() => {
            console.log("created user");
        });
    };

    // Call this function when the form is submitted
    const verifyUser = (e) => {
        e.preventDefault();
        
        logEvent(firebaseAnalytics, 'Clicked on verify user')
                
        var signinButton = document.getElementById("signInButton");
        signinButton.style.display = "none";
        var conditions = 0;
 
        if (nameInput.value.length > 0) {
            conditions += 1;
        }

        if (contactInput.value.length === 10) {            
            numm = contactInput.value;
            conditions += 1;
        } else {
            signinButton.style.display = "block";
            alert("Please enter a valid mobile no.");
        }

        if (emailInput.value.length > 0) {
            conditions += 1;
        }

        if (conditions === 3) {
        } else {
            signinButton.style.display = "block";
            return;
        }
        
        logEvent(firebaseAnalytics, 'Created user data.')
        writeUserData( Date.now() + Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000 + "_"  + nameInput.value , userVideoUrl);
        nextPage();

    };

    const handleTNC = () => {
        logEvent(firebaseAnalytics, 'Visited TNC')
        const DataUploadScreen = document.getElementById(
            "DataUploadScreenInner"
        );
        const tncScreen = document.getElementById("TNCScreen");

        tncScreen.classList.remove("hidden");
        DataUploadScreen.classList.add("hidden");
    };

    const handleTNCBack = () => {
        const DataUploadScreen = document.getElementById(
            "DataUploadScreenInner"
        );
        const tncScreen = document.getElementById("TNCScreen");

        tncScreen.classList.add("hidden");
        DataUploadScreen.classList.remove("hidden");
    };

    return (
        <>
            <div id="DataUploadScreenInner" className="panel">
                <div className="navBar">
                    <div onClick={previousPage} className="backBtn"></div>
                    <div onClick={handleShare} className="shareBtn"></div>
                </div>
                <div className="tooyumxbb"></div>
                <div className="authText"></div>
                <form id="userForm" className="userInput" onSubmit={verifyUser}>
                    <input
                        className="inputField"
                        placeholder="Full Name*"
                        id="nameInput"
                        type="text"
                        required
                    />
                    <input
                        className="inputField"
                        placeholder="Contact Number*"
                        id="numberInput"
                        type="text"
                        maxLength={10}
                        required
                    />
                    <input
                        className="inputField"
                        placeholder="Email ID*"
                        id="emailInput"
                        type="email"
                        required
                    />

                    <div className="TNCAgree">
                        <input
                            type="checkbox"
                            name="TnC"
                            id="tncCheckbox"
                            required
                        />
                        <p onClick={handleTNC} className="tncText">
                            By continuing you agree to <u>T&C*</u>
                        </p>
                    </div>

                    <button
                        id="signInButton"
                        type="submit"
                        className="sendOtpButton"
                    >
                        Submit
                    </button>
                </form>
                <div className="jioCinemaHome"></div>
                <div className="legal"></div>
            </div>            

            <div id="TNCScreen" className="panel hidden">
                <div className="navBar">
                    <div onClick={handleTNCBack} className="backBtn"></div>
                    <div onClick={handleShare} className="shareBtn"></div>
                </div>

                <div id="tncCloseContainer">
                    <div onClick={handleTNCBack} className="tncClose"></div>
                </div>

                <div className="TNCScroll">
                    <p className="TNCText">
                        <b>“Too Yumm! Chips Khao Bigg Boss OTT Jao” CONTEST</b>
                        <br />
                        <br />
                        <b>Terms and Conditions</b>
                        <br />
                        This "Too Yumm! Chips Khao Bigg Boss OTT Jao" contest
                        ("Contest") is brought to you by Guiltfree Industries
                        Limited (hereinafter referred to as “the Company”).
                        Please read these terms and conditions ("T&Cs") before
                        entering the Contest. You agree that, by participating
                        in this Contest, you will be bound by these T&Cs and you
                        acknowledge and agree that you satisfy all Contest
                        eligibility requirements as provided herein below. For
                        the purposes of these T&Cs, wherever the context so
                        requires "You" or "Your" shall mean any natural person
                        who is a participant in the Contest.
                        <br />
                        <br />
                        <b>ELIGIBILITY:</b>
                        <br />
                        You need to fulfil the following eligibility criteria to
                        enter the Contest: (a) You should be an individual legal
                        resident of the Republic of India (b) You should be of
                        an age 18 years or above at the time of entry into the
                        Contest (c) You should have a valid proof of identity
                        and age in the form of a copy of Aadhar Card / PAN Card
                        / Driving License / Voter ID / Indian passport.
                        Employees of Guiltfree Industries Limited, their
                        immediate family members (spouses, domestic partners,
                        parents, grandparents, siblings, children and
                        grandchildren), and advisors, and advertising / contest
                        agencies are not eligible to enter the Contest.
                        <br />
                        By participating in this contest, you agree to adhere to
                        the following content guidelines for your video
                        submission:
                        <br />
                        (a) Videos must not contain any content that is abusive,
                        defamatory, offensive, or hateful. This includes, but is
                        not limited to, language, gestures, or visuals that can
                        be deemed disrespectful or harmful. (b) Submissions must
                        not include any discriminatory content based on race,
                        gender, sexual orientation, religion, nationality,
                        disability, or any other characteristic. (c) Videos must
                        refrain from expressing, endorsing, or promoting any
                        political opinions, agendas, or propaganda. (d) Do not
                        include personal information of others without their
                        explicit consent. This includes names, addresses, phone
                        numbers, and any other personally identifiable
                        information. (e) Submissions must be original and
                        created by the participant. Do not include any content
                        (music, images, video clips) that you do not have the
                        rights to use. (f) Videos must not depict or encourage
                        any dangerous activities or behaviours that could result
                        in harm or injury to the participant or others. (g)
                        Submissions must not infringe on any third party's
                        intellectual property rights, including copyrights,
                        trademarks, or patents. (h) Videos should not include
                        any advertisements or promotional content for products,
                        services, or brands other than those associated with the
                        contest. (i) All submissions must comply with the
                        applicable local, state, national, and international
                        laws and regulations. (j) Videos should be suitable for
                        a general audience, including minors. Avoid any
                        explicit, vulgar, or inappropriate content.
                        <br />
                        <br />
                        <b>DETAILS OF THE CONTEST AND HOW TO ENTER:</b>
                        <br />
                        This Contest will be live from 11th June 2024 from
                        11:00:00 AM (IST) to 27th June 2024 11:59:59 PM (IST)
                        only ("Contest Period"). 2. To enter, you must, during
                        the Contest Period: (a) Create a video of you convincing
                        the judging panel why you are the biggest Bigg Boss fan
                        (b) You must be holding a pack of any Too Yumm! product
                        in while creating the video (c) Upload the video on the
                        website ({document.location.href}) The top 20 Winners
                        will be shortlisted for this contest and the final
                        winner will be decided basis the decision made by the
                        company and Jio Cinema. Even if you are selected, you
                        will be required to prove your age, failing which, you
                        will be disqualified from the Contest.
                        <br />
                        <br />
                        <b>MAILING LIST:</b>
                        <br />
                        By entering the Contest, you consent to being placed on
                        a mailing list for promotional and other materials for
                        Guiltfree. You may update or change your email
                        preferences at the email preferences page. OTHER
                        CONTESTS:
                        <br />
                        Please note that we may be running similar contests or
                        promotions at the same time as this Contest. By entering
                        this Contest, you will not be eligible to receive any
                        benefit and/ or prize awarded in any other promotion/
                        contest unless you enter each promotion/ contest
                        separately.
                        <br />
                        <br />
                        <b>ODDS OF WINNING:</b>
                        <br />
                        Odds of winning depend on the number of eligible entries
                        received. Eligible entries are ones that satisfy the
                        eligibility criteria outlined above and follow the steps
                        outlined in the "Details of the Contest and the How to
                        Enter" section above. In case of any dispute or
                        difference in respect of this competition the decision
                        of the Company/Organizers shall be final and binding on
                        the Contestants.
                        <br />
                        <br />
                        <b>PRIZE DRAWING:</b>
                        <br />
                        The Company will notify the participants, eligible to
                        claim the Prize, by e-mail / SMS following after the
                        contest end date ("Notification Date"). By agreeing to
                        participate and enter into this Contest, you agree that
                        you have a valid proof of identity and age in the form
                        of a copy of Aadhar /PAN Card / Driving License / Voter
                        ID / Indian passport and you may be required to share
                        the said documents with the Company as and when
                        requested. You will automatically forfeit your claim to
                        the Prize if you do not meet the eligibility criteria or
                        do not comply with these T&Cs. Further, if selected and
                        eligible, you will need to claim the Prize within 7 days
                        from the Notification Date, failing which, you will deem
                        to have forfeited the Prize. The procedure of claiming
                        the Prize will be as instructed by the Company wherein
                        you may be required to visit appropriate web-links and
                        provide requisite details. Each Prize will be awarded
                        "AS IS" and WITHOUT WARRANTY OF ANY KIND, express or
                        implied (including, without limitation, any implied
                        warranty of merchantability or fitness for a particular
                        purpose). 
                        <br />
                        <br />
                        <b>ADDITIONAL TERMS:</b>
                        <br />
                        The Company may, to the maximum extent permitted by
                        applicable law and in its sole discretion, change any of
                        the terms of these T&Cs or cancel the Contest at any
                        time; or modify, terminate, or suspend the Contest
                        should viruses, worms, bugs, unauthorized human
                        intervention or other causes beyond its control corrupt
                        or impair the administration, security, fairness or
                        proper play of the Contest or submission of entries. The
                        Company is not responsible for: (a) lost, misdirected,
                        late, incomplete, or unintelligible entries or for
                        inaccurate entry information, whether caused by you or
                        by any of the equipment or programming associated with
                        or utilized in the Contest, or by any technical or human
                        error that may occur in the processing of entries; (b)
                        any printing or typographical errors in any materials
                        associated with the Contest; (c) any error in the
                        operation or transmission, theft, destruction,
                        unauthorized access to, or alteration of, entries, or
                        for technical, network, telephone, computer, hardware or
                        software, malfunctions of any kind, or inaccurate
                        transmission of, or failure to receive any entry
                        information on account of technical problems or traffic
                        congestion on the Internet or at any website; or (d)
                        injury or damage to your or any other computer or mobile
                        resulting from downloading any materials in connection
                        with the Contest. The Company may, in its sole
                        discretion, disqualify any individual found to be: (a)
                        tampering with the entry process or the operation of the
                        Contest (b) acting in violation of these T&Cs; or (c)
                        acting in an unsportsmanlike or disruptive manner or
                        with intent to annoy, abuse, threaten or harass any
                        other person. If your entry is incomplete or if you use
                        robotic, automatic, programmed or similar entry methods,
                        your entry will be void. The authorized subscriber of
                        the e-mail account used to enter the Contest at the
                        actual time of entry will be deemed to be the
                        participant and must comply with these T&Cs in the event
                        of a dispute as to entries submitted by multiple users
                        having the same e-mail account. The authorized account
                        subscriber is the natural person who is assigned an
                        e-mail address by an internet access provider, on-line
                        service provider, or other organization responsible for
                        assigning e-mail addresses for the domains associated
                        with the submitted e-mail addresses.
                        <br />
                        <br />
                        <b>MISCELLANEOUS:</b>
                        <br />
                        The Contest and all accompanying materials are owned by
                        the Company. All rights reserved. No requests for
                        transfer or assignment or redemption of the benefits
                        shall be entertained. You agree that all decisions made
                        by the Company related to the Contest are final and
                        binding on you. Failure by the Company to enforce any of
                        these T&Cs in any instance shall not be deemed to be a
                        waiver of the T&Cs and shall not give rise to any claim
                        by any person. These T&Cs are governed by the laws of
                        India and courts at Kolkata shall have the exclusive
                        jurisdiction in respect of any disputes or any matter
                        arising here from.
                        <br />
                        <br />
                        <b>FORCE MAJEURE:</b>
                        <br />
                        This Contest is subject to force majeure circumstances
                        including without limitation, floods, natural disasters,
                        war, act of terror, political unrests, technical snags,
                        act of God or any circumstance beyond the reasonable
                        control of the Company ("Force Majeure Event"). The
                        Company shall be not liable for any delay or adverse
                        effect caused to you as a result of a Force Majeure
                        Event.
                        <br />
                        <br />
                        <b>PUBLICITY RELEASE:</b>
                        <br />
                        By participating in the Contest, you give the Company
                        and/ or its affiliates permission to use your name,
                        likeness, image, voice, and/or appearance as such may be
                        embodied in any pictures, photos, video recordings,
                        audiotapes, digital images, and the like, taken or made
                        in relation to the Contest and any promotions, events,
                        or contests to follow. You agree that the Company and/
                        or its affiliates have the right to publish your saved
                        item details for any communication, promotions, events,
                        or contests that follow. You agree that the Company and/
                        or its affiliates have complete ownership of such
                        pictures, videos etc., including the entire copyright,
                        and may use them for any purpose. These uses include,
                        but are not limited to illustrations, bulletins,
                        exhibitions, videotapes, reprints, reproductions,
                        publications, advertisements, and any promotional or
                        educational materials in any medium now known or later
                        developed, including the internet. You acknowledge that
                        you will not receive any compensation, etc. for the use
                        of such pictures, videos, etc., and hereby release The
                        Company and/ or its affiliates and its agents and
                        assigns from any and all claims which arise out of or
                        are in any way connected with such use. You give your
                        consent to the Company and/or its affiliates, agents and
                        assigns to use your name and likeness to promote the
                        Contest and any promotions, events, or contests to
                        follow.
                        <br />
                        <br />
                        <b>LIABILITY RELEASE:</b>
                        <br />
                        This Contest is being made purely on a "best effort"
                        basis and participating in this Contest is voluntary. By
                        participating in the Contest, you will be legally bound
                        hereby, to release from liability, and hold harmless the
                        Company, and any of its affiliates, employees or agents
                        representing or related to the Company and its
                        services/products. This release is for any and all
                        liability for personal injuries (including death),
                        property loss or damage, and misuse of the benefits/
                        Prizes offered under this Contest, in connection with
                        any activity or directly or indirectly, by reason of the
                        acceptance, possession, or participation in the Contest,
                        even if caused or contributed to by the Company’s
                        negligence. The Company will not be liable for any
                        action taken against the Contest Winner resulting from
                        his/her misbehaviour, assault, defamatory acts, bodily
                        harm/injury and any such acts caused to the Bigg Boss
                        Contestants and any other person associated with this
                        Contest. Subject to any applicable law (a) All
                        warranties of any kind whatsoever, whether express or
                        implied, are hereby expressly DISCLAIMED by
                        Organisers/Company including, but not limited to,
                        meeting of the participant's requirements or
                        aspirations, timeliness, security, the results or
                        reliability of any offer/scheme, or the delivery,
                        quality, quantity, merchantability, fitness for use or
                        non-infringement in respect of any Gratification, goods,
                        services, benefits or awards acquired or obtained
                        through the Contest or any transactions effected through
                        the Contest; (b) The participant expressly agrees that
                        his/her participation in the Offer is at the
                        participant's sole risk and is governed by the Terms and
                        Conditions herein; and (c) No advice or information
                        whether by representations, oral, written or pictorial
                        derived from the website or through the Contest shall be
                        construed to mean the giving of any warranty of any kind
                        by the Organisers.
                        <br />
                        <br />
                        <b>WINNER ANNOUNCEMENT:</b>
                        <br />
                        After the Company confirms the winner/s, the Company
                        will contact each winner individually through
                        email/message. <br />
                        <br />
                        <b>PRIVACY NOTICE:</b>
                        <br />
                        All information submitted in connection with this
                        Contest will be treated in accordance with these T&Cs{" "}
                        <br />
                        <br />
                        <b>Jurisdiction</b>
                        <br />
                        These terms and conditions are governed in accordance
                        with the laws of India. Any dispute shall be subject to
                        the exclusive jurisdiction of Courts of Kolkata.
                    </p>
                </div>
            </div>
        </>
    );
}

export default DataUploadScreen;
