import React, { useContext } from "react";
import FunctionContext from "../Utils/FunctionContext";
import "../../Styles/ThankyouScreen.css";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";

function ThankyouScreen() {
    const { nextPage, previousPage } = useContext(FunctionContext);

    const handleShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Tooyumm x Bigboss',
              text: 'Grab a chance to be the biggest big boss fan. Click on the link to participate.',
              url: document.location.href,
            });
            console.log('Content shared successfully');
          } catch (error) {
            console.error('Error sharing content:', error);
          }
        } else {
          console.log('Web Share API is not supported in this browser.');
        }
      };

    const handleInsta = ()=>{
        logEvent(firebaseAnalytics, 'Clicked on Follow us on Instagram button')
        const instaUrl = "https://www.instagram.com/tooyumm/"
        window.location.href = instaUrl;
    }

    return (
        <>
            <div className="panel">
                <div className="navBar">
                    <div onClick={previousPage} className="backBtn"></div>                    
                </div>
                <div className="tooyumxbb"></div>
                <div className="thankyouText"></div>

                <button onClick={handleInsta} id="followUsButton"><p>Follow us on </p> <div className="instaLogo"></div> </button>
                
                
                <br />
                <br />
                <div onClick={handleShare} className="shareLink">
                    <u>Share</u>
                    <div className="shareLinkIcon"></div>
                </div>

                <div className="jioCinema"></div>
                <div className="legal"></div>
            </div>
        </>
    );
}

export default ThankyouScreen;
