import React, {useContext} from "react";
import FunctionContext from "../Utils/FunctionContext";
import "../../Styles/VerifyOtpScreen.css"

function OTPScreen() {

    const {nextPage, previousPage} = useContext(FunctionContext);


    return(
        <>
            <div className="panel">
                <div className="navBar">
                    <div onClick={previousPage} className="backBtn"></div>
                    <div className="shareBtn"></div>
                </div>
                <div className="tooyumxbb"></div>    
                <div className="verifyOtpText"></div>  
                <input id="otpInput" type="text" placeholder="Enter OTP" />  
                <p id="resendBtn"><u>Resend OTP</u></p>  
                <button id="verifyOtpBtn" onClick={nextPage}>Verify otp</button>
                <div className="legal"></div>
                {/* <button onClick={previousPage}>back</button> */}
            </div>
        </>
    )
}

export default OTPScreen;
