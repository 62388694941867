import "./App.css";
import { useRef, useState } from "react";
import UploadPanel from "./Components/Panels/UploadPanel";
import SplashScreen from "./Components/Panels/SplashScreen";
import ParticipateScreen from "./Components/Panels/ParticipateScreen";
import DataUploadScreen from "./Components/Panels/DataUploadScreen";
import OTPScreen from "./Components/Panels/OTPScreen";
import ThankyouScreen from "./Components/Panels/ThankyouScreen";
import FunctionContext from "./Components/Utils/FunctionContext";
import UserContext from "./Components/Utils/UserContext";

function App() {

    const [userVideoUrl, setUserVideoUrl] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userName, setUserName] = useState(null);

    const splashScreen = useRef(null);
    const participateScreen = useRef(null);
    const uploadScreen = useRef(null);
    const dataUploadScreen = useRef(null);
    const otpScreen = useRef(null);
    const thankyouScreen = useRef(null);

    const screensArray = [
        splashScreen,
        participateScreen,
        uploadScreen,
        dataUploadScreen,
        otpScreen,
        thankyouScreen,
    ];



    function HideScreens() {
        screensArray.forEach((screen) => {
            screen.current.classList.add("hidden");
        });
    }

    const ShowSplashScreen = () => {
        HideScreens();
        splashScreen.current.classList.remove("hidden");
    };

    const ShowParticipateScreen = () => {
        HideScreens();
        participateScreen.current.classList.remove("hidden");
    };

    const ShowDataUploadScreen = () => {
        HideScreens();
        dataUploadScreen.current.classList.remove("hidden");
    };

    const ShowThankyouScreen = () => {
        HideScreens();
        thankyouScreen.current.classList.remove("hidden");
    };

    const ReloadSite = () => {
        window.location.reload();
    };

    return (
        <>
            <meta
                name="viewport"
                content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1, user-scalable=0"
            />
            <UserContext.Provider value={{ userVideoUrl, setUserVideoUrl, userEmail, setUserEmail, userName, setUserName }}>
                <div id="container">
                    <div className="leftElements"></div>
                    <div className="rightElements"></div>
                    <div
                        id="SplashScreen"
                        ref={splashScreen}
                        className="screen hidden"
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowParticipateScreen,
                                previousPage: ReloadSite,
                            }}
                        >
                            <SplashScreen />
                        </FunctionContext.Provider>
                    </div>

                    <div
                        id="ParticipateScreen"
                        ref={participateScreen}
                        className="screen "
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowDataUploadScreen,
                                previousPage: ShowSplashScreen,
                            }}
                        >
                            <ParticipateScreen />
                        </FunctionContext.Provider>
                    </div>

                    <div
                        id="UploadScreen"
                        ref={uploadScreen}
                        className="screen hidden"
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowDataUploadScreen,
                                previousPage: ShowParticipateScreen,
                            }}
                        >
                            <UploadPanel />
                        </FunctionContext.Provider>
                    </div>

                    <div
                        id="DataUploadScreen"
                        ref={dataUploadScreen}
                        className="screen hidden"
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowThankyouScreen,
                                previousPage: ShowParticipateScreen,
                            }}
                        >
                            <DataUploadScreen />
                        </FunctionContext.Provider>
                    </div>

                    <div
                        id="OTPScreen"
                        ref={otpScreen}
                        className="screen hidden"
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowThankyouScreen,
                                previousPage: ReloadSite,
                            }}
                        >
                            <OTPScreen />
                        </FunctionContext.Provider>
                    </div>

                    <div
                        id="ThankyouScreen"
                        ref={thankyouScreen}
                        className="screen hidden"
                    >
                        <FunctionContext.Provider
                            value={{
                                nextPage: ShowSplashScreen,
                                previousPage: ReloadSite,
                            }}
                        >
                            <ThankyouScreen />
                        </FunctionContext.Provider>
                    </div>

                    {/* <div className="legal"></div> */}
                </div>
            </UserContext.Provider>
        </>
    );
}

export default App;
